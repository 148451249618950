var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.setValue,"headers":_vm.headers,"items-per-page":-1,"fixed-header":"","hide-default-footer":"","height":_vm.list_height + 'px'},on:{"dblclick:row":function ($event, ref) {
	var item = ref.item;

	return _vm.rowClick(item);
}},scopedSlots:_vm._u([{key:"item.order_status",fn:function(ref){
var item = ref.item;
return [(item.order_status == _vm.$receivedOrderStatus.new_received_order)?_c('div',{staticClass:"red--text"},[_vm._v(" 新規受注 ")]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.received_ordered)?_c('div',[_vm._v(" 受注済み ")]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.fixed_delivery_date)?_c('div',[_vm._v(" 納期回答済 ")]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.estimated_shipment)?_c('div',[(!_vm.isSameOrBeforeDay(item.fixed_delivery_date))?_c('span',[_vm._v("出荷予定")]):_vm._e(),(_vm.isSameOrBeforeDay(item.fixed_delivery_date))?_c('span',{staticClass:"red--text"},[_vm._v("出荷予定")]):_vm._e()]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.shipped)?_c('div',{staticStyle:{"color":"#00b050"}},[_vm._v(" 出荷済み ")]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.cancel_received_order)?_c('div',[_vm._v(" 受注キャンセル ")]):_vm._e(),(item.order_status == _vm.$receivedOrderStatus.division_storing)?_c('div',{staticStyle:{"color":"#ff0000"}},[_vm._v(" 分納 ")]):_vm._e()]}},{key:"item.desired_delivery_date",fn:function(ref){
var item = ref.item;
return [(item.is_asap && !item.desired_delivery_date)?_c('div',[_vm._v("即日")]):_vm._e(),(!item.is_asap && item.desired_delivery_date)?_c('div',[_vm._v(" "+_vm._s(_vm.omitAD(item.desired_delivery_date))+" ")]):_vm._e(),(!item.is_asap && !item.desired_delivery_date)?_c('div',[_vm._v(" "+_vm._s("ー")+" ")]):_vm._e()]}},{key:"item.short_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.short_name + " / " + item.branch_short_name)+" ")]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(" "+_vm._s(product.product_name)+" ")])])})}},{key:"item.product_code",fn:function(ref){
var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index},[(product.product_code)?_c('div',[_vm._v(" "+_vm._s(product.product_code)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.customer_pn",fn:function(ref){
var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index},[(product.customer_pn)?_c('div',[_vm._v(" "+_vm._s(product.customer_pn)+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])])})}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return _vm._l((item.products),function(product,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(Number(product.quantity).toLocaleString()))])])})}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [(item.destination)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"rgba(0, 0, 0, 1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v("◯")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.destination))])])],1):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}},{key:"item.fixed_delivery_date",fn:function(ref){
var item = ref.item;
return [(item.fixed_delivery_date)?_c('div',[_vm._v(" "+_vm._s(_vm.omitAD(item.fixed_delivery_date))+" ")]):_c('div',[_vm._v(" "+_vm._s("ー")+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }